import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faGlobe, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faLinkedinIn, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

import logo from '../images/logo.png'; // Adjust the path as necessary

function Footer() {
    return (
        <div className='footer'>
            <footer className="myfooter">
                <div className="myfooter-secton">
                    <div className="myfooter-logo">
                        <img src={logo} alt="Company Logo" />
                        <h1>Contact and Support</h1>
                    </div>
                    <div className="myfooter-contact">
                        <div className="myfooter-contact-item">
                            <FontAwesomeIcon className='icon' icon={faPhone} />
                            <span>+255 775 666 800</span>
                        </div>
                        <div className="myfooter-contact-item">
                            <FontAwesomeIcon className='icon' icon={faEnvelope} />
                            <span>info@techmento.co.tz</span>
                        </div>
                        <div className="myfooter-contact-item">
                            <FontAwesomeIcon className='icon' icon={faGlobe} />
                            <span><a href="https://www.techmento.com" target="_blank" rel="noopener noreferrer">www.techmento.co.tz</a></span>
                        </div>
                    </div>
                </div>

                <div className="myfooter-section">
                    <h1>About Us</h1>
                    <div className="myfooter-line"></div>
                    <p>We are dedicated to providing top-notch IT solutions that enhance your productivity and streamline your processes.
                        You can find us on our Social media. Please follow us 
                    </p>
                    <div className="myfooter-social">
                        <a href="https://www.facebook.com/techmento" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        <a href="https://www.linkedin.com/company/Techmento_Solution" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                        <a href="https://twitter.com/techmento" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                        <a href="https://www.instagram.com/officialtechmento" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    </div>
                </div>

                <div className="myfooter-section">
                    <h1>Visit Us</h1>
                    <div className="myfooter-line"></div>
                    <div className="myfooter-address">
                        <p>
                        Work Time <br/>
                        Monday to Friday 09:00 AM - 04:00 PM<br/>
                        Sartuday 09:00 AM - 01:00 PM<br/><br/>
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                        <span> Kijangwani, Zanzibar</span></p>
                    </div>
                    <a href="https://www.google.com/maps?q=123+Tech+Street,+Silicon+Valley,+CA+94043" target="_blank" rel="noopener noreferrer" className="myfooter-map-link">View on Google Maps</a>
                </div>
            </footer>
            <div className="myfooter-copyright">
                <p>&copy; 2024 Techmento. All Rights Reserved.</p>
                <p>Developed and Maintained By Techmento</p>
            </div>
        </div>
    );
}

export default Footer;
