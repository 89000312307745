import React from 'react';
import './ContactUs.css';
import Header from './Header';
import Footer from './Footer';
import { FaPhone, FaEnvelope, FaGlobe, FaMapMarkerAlt } from 'react-icons/fa';

function ContactUs() {
    return (
        <div>
            <Header />
            <div className="contact-container">
                <div className="contact-card contact-info">
                    <h2>Contact Information</h2>
                    <div className="contact-item">
                        <FaPhone className="contact-icon" />
                        <p>+255 775 666 800</p>
                    </div>
                    <div className="contact-item">
                        <FaEnvelope className="contact-icon" />
                        <p>info@techmento.co.tz</p>
                    </div>
                    <div className="contact-item">
                        <FaGlobe className="contact-icon" />
                        <p><a href="www.techmento.co.tz" target="_blank" rel="noopener noreferrer">www.techmento.co.tz</a></p>
                    </div>
                    <div className="contact-item">
                        <FaMapMarkerAlt className="contact-icon" />
                        <p>Kijangwani, Zanzibar</p>
                    </div>
                </div>

                <div className="contact-card inquiry-form">
                    <h2>Inquiry Form</h2>
                    <form action="https://your-email-api-endpoint" method="POST">
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" required />

                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" required />

                        <label htmlFor="phone">Phone</label>
                        <input type="tel" id="phone" name="phone" required />

                        <label htmlFor="service">Service Needed</label>
                        <select id="service" name="service" required>
                            <option value="">Select a Service</option>
                            <option value="Software Development">Software Development</option>
                            <option value="Equipment Supply">Equipment Supply</option>
                            <option value="Networking">Networking</option>
                            <option value="IT Support and Maintenance">IT Support and Maintenance</option>
                            <option value="IT Consultancy">IT Consultancy</option>
                            <option value="Cyber Security">Cyber Security</option>
                        </select>

                        <label htmlFor="service-description">Service Description</label>
                        <textarea id="service-description" name="service-description" rows="4" required></textarea>

                        <button type="submit">Send Inquiry</button>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ContactUs;
