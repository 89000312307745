import React from 'react';
import './SoftwareDevelopment.css';
import Header from '../Header';
import Footer from '../Footer';
import { FaAndroid, FaApple, FaCode, FaCheckCircle,FaNetworkWired, FaLink , FaShieldAlt} from 'react-icons/fa';

function SoftwareDevelopment() {
    return (
        <div>
            <Header />
            <div className="software-dev-container">
                <div className="software-dev-header">
                    <h1>Our Expertise in Software Development</h1>
                    <p>We transform your ideas into innovative Android, iOS, and web applications with exceptional user experiences and seamless performance.</p>
                </div>

                 <div className="software-dev-details">
    <div className="software-dev-detail">
        <FaAndroid className="detail-icon android-icon" />
        <h2>Android Development</h2>
        <p>Develop high-performing Android apps with sleek designs, ensuring optimal user engagement and seamless functionality for your business needs.</p>
    </div>

    <div className="software-dev-detail">
        <FaCode className="detail-icon web-icon" />
        <h2>Web Application Development</h2>
        <p>Delivering secure, responsive, and feature-rich web applications to power your digital presence and elevate your business operations.</p>
    </div>

    <div className="software-dev-detail">
        <FaApple className="detail-icon ios-icon" />
        <h2>iOS Development</h2>
        <p>Create cutting-edge iOS applications with smooth performance and engaging user experiences tailored to Apple devices.</p>
    </div>
</div>

                <div className="why-choose-us">
                    <h2>Why Choose Our Software Development Service?</h2>
                    <ul className="reasons-list">
                        <li>
                            <FaCheckCircle className="reason-icon" />
                            <span className="reason-text">
                                <b>Expertise:</b> Decades of combined experience in mobile and web development.
                            </span>
                        </li>
                        <li>
                            <FaCheckCircle className="reason-icon" />
                            <span className="reason-text">
                                <b>Customization:</b> Tailored solutions to address your specific business needs.
                            </span>
                        </li>
                        <li>
                            <FaCheckCircle className="reason-icon" />
                            <span className="reason-text">
                                <b>Performance:</b> Seamless user experiences with optimized performance.
                            </span>
                        </li>
                        <li>
                            <FaCheckCircle className="reason-icon" />
                            <span className="reason-text">
                                <b>Support:</b> Dedicated support throughout your project lifecycle.
                            </span>
                        </li>
                        <li>
                            <FaCheckCircle className="reason-icon" />
                            <span className="reason-text">
                                <b>Agile Delivery:</b> Fast development cycles to bring your product to market quickly.
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default SoftwareDevelopment;
